import React, {useState} from "react";

const RegisterForm = (props:any) => {

    const [isRegisterInProgress, setIsRegisterInProgess] = useState(false);
    const [isAcceptedTC, setIsAcceptedTC] = useState(false);

    const goToLoginHandler = () => {
        props.onSelectRegisterOrLogin(false);
    }

    const tcClickHandler = () => {
        setIsAcceptedTC(!isAcceptedTC);
    }  

    const registerClickHandler = () => {
        setIsRegisterInProgess(true);
        props.onRegisterClick()
    }

    return (
        <div className="justify-center">
            <div>
                <div className="flex justify-center">
                    <input type="checkbox" name="TandC" checked={isAcceptedTC} disabled={isRegisterInProgress} onChange={tcClickHandler} 
                            className="flex w-[24px] h-[24px] padding-[10px] justify-itmes-center shrink-0 items-center mt-1 mr-2 "></input>
                    <label className="mt-1 font-normal">I accept the <a href="https://google.com/" className="text-[#1B7D93] font-semibold">Terms and Conditions</a></label>
                </div>
                <div className="flex justify-center mt-3">
                    <button onClick={registerClickHandler} disabled={isRegisterInProgress || !isAcceptedTC || !props.buttonEnabled} 
                    className="font-bold block py-3.5 w-full text-white text-sm rounded-md  bg-[#3B3C43] hover:bg-gray-500 disabled:cursor-not-allowed ">CONFIRM YOUR EMAIL</button>
                </div>
                <div className="flex justify-center">
                    <a href="#Login" onClick={goToLoginHandler} className="text-[14px] font-[600] leading-6	m-3 text-[#3B3C43]">Sign in for existing users</a>
                </div>
            </div>
        </div>
    );
};

export default RegisterForm;
