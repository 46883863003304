import react from 'react'
import punchOpen from '../images/punch-open.svg'
import { Link } from 'react-router-dom';
import {mumbaiPolygonScan} from '../utils/network'

const SpendItemOpen = (props:any) => {

    const item = {
        id: props.spendItem.id,
        date: props.spendItem.date,
        txnHash: props.spendItem.txnHash,
        amount: props.spendItem.amount
    };

    const itemClosed = ">";
    const txnHashLink = mumbaiPolygonScan + item.txnHash;

    return (
        <>
        <div className="flex">
            <div className="relative w-10 h-10">
                <img id={item.id} key={item.id} className="w-full h-full" src={punchOpen}/>
                <div className="absolute inset-0 flex items-center justify-center text-sm  text-[#dde5e699]">{item.id + 1}</div>
            </div>
            <span className='m-3 text-sm'>Star Received</span>
            <span className='m-3 text-sm'>{item.date}</span>
            <button className='ml-5 rotate-90 text-sm' id={item.id} onClick={props.onOpenClose}>{itemClosed}</button>
        </div> 
        <div>
                <p className='px-5'>.................................................</p>
                <Link to={txnHashLink} className='px-5 text-sm underline text-blue-200'>View transaction</Link>
                <p className='px-5 text-sm'>Redeemed: {item.date}</p>
                <p className='px-5 text-sm'>Amount: {item.amount}</p>
        </div>
            
    </>
    )


}

export default SpendItemOpen