import { useState,  useEffect, useCallback } from "react";
import LoggedinHeader from "../components/LoggedinHeader";
import PunchCard from "../components/PunchCard";
import Card from "../components/Card";
import { Link, useLocation } from "react-router-dom";
import QualifiedSpends from "../components/QualifiedSpends";
import { getPunchTokens, getRedeemedTokens, setMerchantWallet, TransferredTokens } from "../utils/dataAccessLayer";
import { mumbaiPolygonScan } from '../utils/network';
import leftArrow from '../images/left-arrow.png'
import { magic } from "../magic/MagicProvider";
import BusySpinner from "../components/BusySpinner";


const ConsumerLoggedInPage = (props:any) => {

    const initialRewardItems:TransferredTokens[] = []

    //extract state passed from the previous page
    const {state} = useLocation();

    const [punchCount, setPunchCount] = useState(0);
    const [showRewards, setShowRewards] = useState(false);
    const [rewardsList, setRewardsList] = useState(initialRewardItems);
    const [spendLoading, setSpendLoading] = useState(false);

    useEffect( () => {
        (window as any).setMerchant = setMerchantWallet;
    }, []);

    //fetch punch count from alchemy rpc

    let rewardsContent:any = <></>;
    if(rewardsList.length > 0) {

        rewardsContent = rewardsList.map((item) => {

            const txnHashLink = mumbaiPolygonScan + item.txnHash;
            
            return (
                <tr className='flex' id={item.tokenId} key={item.tokenId}>
                    <td className='flex-1 w-1/2 text-center text-sm'>
                        <Link to={txnHashLink} className='px-5 text-sm underline text-blue-500'>{item.tokenId}</Link>
                    </td>
                    <td className='flex-1 w-1/2 text-center'>$100.00</td>
                </tr>
            );
    
        });
    }

    const getPunches = useCallback(async () => {

        const punches = await getPunchTokens();

        setPunchCount(punches)

    }, []);

    const getRewards = useCallback(async () => {

        const rewards = await getRedeemedTokens();

        if(rewards.length == 0) return;
      
        setRewardsList(() => {
            return[...rewards];
        });

    }, []);

    useEffect(() => {
        getPunches();
        getRewards();
  }, []);  

  const showRedeemedTokensHandler = () => {
        setShowRewards(true);
    }

  const hideRedeemedTokensHandler = () => {
        setShowRewards(false);
    }

  const makeSpend = async () => {
    if (spendLoading) { 
        return; 
    }
    setSpendLoading(true);
    const userInfo = await magic.user.getInfo();
    const email = userInfo.email;
    const amount = Math.round((Math.random() * 500 + 25)*100)/100;
    const response = await fetch('https://api.q87ydsiufeq78dc8dh.com/spend', {
        method: 'POST',
        headers: {
          Accept: 'application.json',
          'Content-Type': 'application/json',
          Authorization: 'zhsFd2H2MZx792Ii6mt985shuasb',
        },
        body: JSON.stringify(
            {'amount': amount, 
             'email': email,
             'timestamp': new Date(),
        }),
        cache: 'default'
      });
    setSpendLoading(false);
  }

    return (
            <Card>
                <LoggedinHeader email={state.email}/>
                {
                    (!showRewards) &&
                    <>
                        <PunchCard hostPage="consumerPage" punches={punchCount}/>
                        <QualifiedSpends/>
                        <p className="m-4"><a style={{cursor:'pointer'}} className="block text-[#52C7DF] font-normal text-sm mx-5 text-center" onClick={showRedeemedTokensHandler}>Show redeemed rewards</a></p>
                    </>
                }
                {
                    (showRewards) &&
                        <>
                            <table className='w-full my-5'>
                            <thead>
                                <tr className='flex' id="headingRow" key="headingRow">
                                    <th className='flex-1 w-1/2 border-b-2 border-[#52C7DF]'>TOKEN ID</th>
                                    <th className='flex-1 w-1/2 border-b-2 border-[#52C7DF]'>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rewardsContent}
                            </tbody>
                            </table>
                            <div className="mt-3 flex flex-row items-center ">
                                <img src={leftArrow} className= 'w-8 h-8 left-[80px] relative'/>
                                <button onClick={hideRedeemedTokensHandler}                                
                                        className="font-bold block py-3.5 w-full bg-[#3B3C43] hover:bg-gray-500 text-white text-sm rounded-md">RETURN TO TABLE</button>
                            </div>
                        </>
                }
                <div className='fixed bottom-0 left-0 min-w-full'>
                    <div className="container py-10 mx-0 flex flex-col items-center min-w-full">
                        <button className="border-dotted border-2 border-sky-500 items-center p-[8px] hover:bg-blue-400" disabled={spendLoading} onClick={makeSpend}>Swipe my card (testing) { spendLoading && (<BusySpinner/>)} </button>
                    </div>
                </div>
            </Card>
    );
};

export default ConsumerLoggedInPage;