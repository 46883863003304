import React from "react";
import topPartnerLogo from '../images/logo.svg'

const Header = () => {
    return (
        <>
            <img src={topPartnerLogo} className="mx-auto w-[125px] top-[-8px] mb-[18px]"/>
        </>
    );
};

export default Header;