import React, { useEffect, useState } from "react";
import RegisterForm from "./RegisterForm";
import LoginForm from "./LoginForm";
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { loginWithMagicLink } from "../magic/MagicProvider";
import { hostname } from "os";
import userIcon from "../images/icons/user.svg";

const AuthForm = () => {
  const registerRoute = useLocation().hash.includes("Register");
  const [isRegister, setIsRegister] = useState(registerRoute);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);

  const navigate = useNavigate();

  const isEmailValid = (): boolean => {
    if (!email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {

      console.log("email is invalid");
      return false;
    }
    console.log("email is valid");
    return true;
  }

  useEffect(() => {
    setButtonEnabled(isEmailValid());
  }, [email])

  const selectRegisterOrLoginHandler = (isRegister: boolean) => {
    setIsRegister(isRegister);
  };

  const emailChangeHandler = (event: any) => {
    console.log("Email value: " + event.target.value);
    setEmail(event.target.value);
    isEmailValid() ? setEmailError(false) : setEmailError(true);
  }

  const validateEmailHandler = () => {
    setEmailError(!isEmailValid());
  }

  const loginClickHandler = async () => {

    const domain = window.location.host;
    const protocol = window.location.protocol;

    if (isEmailValid()) {

      try {
        const redirectURIPath = protocol + "//" + domain + "/LoginLoadingPage";
        const result = await loginWithMagicLink({ email: email, redirectURI: redirectURIPath });
        setEmail('');

        if (result === 'Login_successful') {

          console.log("Login successful. Loading home page..")
          navigate('/LoginLoadingPage?login=success');
        }

      } catch (e) {
        console.log(e);
      }

    }
  }

  const registerClickHandler = async () => {

    const domain = window.location.host;
    const protocol = window.location.protocol;

    if (isEmailValid()) {

      try {

        const redirectURIPath = protocol + "//" + domain + "/RegisterLoadingPage?isPartner=" + 'false';
        const result = await loginWithMagicLink({ email: email, redirectURI: redirectURIPath });
        setEmail('');

        if (result === 'Login_successful') {
          console.log("Login successful. Registering user...")
          navigate("/RegisterLoadingPage?login=success&isPartner=" + 'false');
        }

      } catch (e) {
        console.log("Error while registering email: " + e);
      }

    }

  }

  return (
    <div>
      <div className="text-center">
        <span className="block mt-3 font-sans text-sm font-semibold">Please enter your email to get started.</span>
        <span className="block mt-2 font-sans text-sm">We'll confirm it's you. Email should arrive shortly.</span>
      </div>

      <div className="relative mt-1 rounded-md p-0 pt-4 pb-4">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span><img className="left-[-4px] relative" src={userIcon}></img></span>
          {/* <span className="text-gray-500 sm:text-sm m-4 inline relative">Email</span> */}
        </div>
        <input value={email} onChange={emailChangeHandler} onBlur={validateEmailHandler} type="text" name="email" id="email" placeholder="youremail@example.com"
          className="inline w-full rounded-[6px] border-0 py-1.5 pl-8 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300
                         placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-8 input:relative placeholder:relative
                      "/>
      </div>

      {isRegister && (
        <RegisterForm buttonEnabled={buttonEnabled} onRegisterClick={registerClickHandler} onSelectRegisterOrLogin={selectRegisterOrLoginHandler} />
      )}
      {!isRegister && (
        <LoginForm buttonEnabled={buttonEnabled} onLoginClick={loginClickHandler} onSelectRegisterOrLogin={selectRegisterOrLoginHandler} />
      )}
    </div>
  );
};

export default AuthForm;