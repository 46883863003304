import { Magic } from 'magic-sdk';
import { LoginProps } from '../utils/types';
import { saveToken } from '../utils/common';
import { RPCError, RPCErrorCode } from 'magic-sdk';
import {ethers} from 'ethers';

const customNodeOptions = {
  rpcUrl: 'https://rpc-mumbai.maticvigil.com/', // Polygon RPC URL
  chainId: 80001, // Polygon chain id
}
const magic = new Magic("pk_live_F0A9BC150135574E", { network: customNodeOptions} );

export {magic};
export type {Magic};

//This method calls the magic API to authenticate using the magic-link method

export const loginWithMagicLink = async ({email, redirectURI}:LoginProps):Promise<string> => {
    try {

      console.log("Before login with magic link")

        const account = await magic?.auth.loginWithMagicLink({ email:email, redirectURI:redirectURI, showUI:true});

        console.log("login with magic link returned: " + account);

        if (account) {
          console.log("Returned from login API " + account);
          saveToken(account);
        
          return "Login_successful";
        }
      } catch (e:any) {
        console.log('login error: ' + JSON.stringify(e));

        if (e instanceof RPCError) {
          switch (e.code) {
            case RPCErrorCode.MagicLinkFailedVerification:
            case RPCErrorCode.MagicLinkExpired:
            case RPCErrorCode.MagicLinkRateLimited:
            case RPCErrorCode.UserAlreadyLoggedIn:
              console.log("Already logged in");
                         
              break;
            default:
            console.log("Something went wrong");
          }
        }

        throw new Error(e);

      }

      console.log("Redirect_Invoked");

      return "Redirect_Invoked";
}


export const getWalletAddress = async () => {
 
  try {
    const userInfo = await magic.user.getInfo();

    return userInfo.publicAddress;
  } catch(e) {
    console.log("Error: " + e);
  }

  return "";
}

