import React from "react";
import Header from "./Header";
import punchCompleteFake from "../images/punch-complete-fake.png";

const MerchantLoggedinHeader = (props: any) => {
    return (
        <>
            <div className="flex justify-between">
                <div>
                        <Header></Header>
                </div>
                <div className="w-1/2 h-1/2 pl-12">
                    <img  src={punchCompleteFake}/>
                </div>
            </div>
            <div className='my-5'>
                <span className="text-left align-middle text-[#3B3C43] text-[14px] font-sans font-semibold">{props.email}</span>
            </div>
        </>
    );
};

export default MerchantLoggedinHeader;