import react from 'react'
import SpendItemOpen from './SpendItemOpen';
import punchOpen from '../images/punch-open.svg'

// type Props = 

const SpendItemClosed = (props:any) => {

    const item = {
        id: props.spendItem.id,
        date: props.spendItem.date,
        transactionId: props.spendItem.transactionId,
        amount: props.spendItem.amount
    };

    const itemClosed = ">";

    return (
        <div className=" flex">
            <div className="relative w-10 h-10">
                <img id={item.id} key={item.id} className="w-full h-full" src={punchOpen}/>
                <div className="absolute inset-0 flex items-center justify-center text-sm  text-[#dde5e699]">{item.id + 1}</div>
            </div>
            <span className='m-3 text-sm'>Star Received</span>
            <span className='m-3 text-sm'>{item.date}</span>
            <button className='ml-5 text-sm' id={item.id} onClick={props.onOpenClose}>{itemClosed}</button>
        </div>

    )
}

export default SpendItemClosed;