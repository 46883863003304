import React, {useEffect, useState, useCallback} from 'react'
import punchOpen from "../images/punch-open.svg"
import { magic } from '../magic/MagicProvider';
import { getToken } from "../utils/common";
import { useSearchParams, useNavigate } from "react-router-dom";
import { endpointQualifiedSpends } from '../utils/network';
import SpendItemClosed from './SpendItemClosed';
import SpendItemOpen from './SpendItemOpen';
import SpendItemEmpty from './SpendItemEmpty';

interface ISpendItem {id:number, date:string, txnHash:string, amount:number};

const QualifiedSpends = () => {

    const [searchParams] = useSearchParams();   
    const [openItem, setOpenItem] = useState(-1);  
    const initialSpendItems:ISpendItem[] = []

    const [spendItems, setSpendItems] = useState(initialSpendItems);

    const openCloseHandler = (event:any) => {
        const id = parseInt(event.target.id );

        if(openItem === id) {
            setOpenItem(openItem === -1 ? id : -1);
        } else {
            setOpenItem(id);
        }
    }

    const itemClosed = ">";

    let spendContent:JSX.Element[] = [];

    if (spendItems.length > 0) {
        spendContent = spendItems.map((item) => (

            <li className='shadow-lg rounded-md m-1 p-2' key={"punch"+ item.id.toString()}>
               {(openItem !== item.id) && 
                <SpendItemClosed spendItem={item} onOpenClose={openCloseHandler}></SpendItemClosed>
               } 
                {(openItem === item.id) && 
                    <SpendItemOpen spendItem={item} onOpenClose={openCloseHandler}></SpendItemOpen>
                }
            </li>
        ));

    }

    //fill empty rows if needed 

    if(spendItems.length < 5) {

        for(let i = spendContent.length;i < 5; i++) {

            console.log("item id: " + i);

            spendContent.push(
                <li className='rounded-md m-1 p-2 border-2 border-dotted border-[##3B3C43]' key={"punch"+ i.toString()}>
                    <SpendItemEmpty spendItem={i}></SpendItemEmpty>
                </li>
            )

        }
    }


    const getQualifiedSpends = useCallback(async () => {

        try {

            let token  = getToken() || '';
            const userInfo = await magic.user.getInfo();

            console.log("User info:" + userInfo);
            console.log("token: " + token)

            const response = await fetch( endpointQualifiedSpends, {
                method:'GET',
                mode:'cors',
                credentials:'omit',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': token || ""
                },
            });

            console.log("After fetch: " + response);

            if( !response.ok) {
                throw new Error(response.statusText + " " + userInfo.email);
            }

            const responseObject = await response.json();

            //clear the existing array
            spendItems.length = 0;
            
            //add new spend items
            responseObject.forEach((s:any, i:number) => spendItems.push({
                        id:i, 
                        txnHash:s.txHash,
                        amount:s.trigger.amount,
                        date:new Date(s.timestamp).toDateString()
                    })
            );

            console.log("retrieved spends: " + JSON.stringify(responseObject));

            setSpendItems(() => {
                return[...spendItems];
            });

        } catch(e) {
            console.log("Error trying to get spends: " + e);
        }

    }, []);

    useEffect(() =>{
        getQualifiedSpends();
    }, []);

    return (
       <ul>
            {spendContent}
        </ul>
        )};

    export default QualifiedSpends;
