import React, {useState, useEffect} from 'react';
import { magic } from '../magic/MagicProvider';
import { QRCode } from 'react-qrcode-logo';
import bgImage from '../images/qrBackground.png';
import qrLogo from '../images/qr-logo.svg';
import { Tag } from './Tag';
import BusySpinner from './BusySpinner';
import pblogo from '../images/pb-logo-transparent.svg';
import closeIcon from '../images/icons/close.svg';
import linkOut from '../images/icons/link-out.svg';
import locationPin from '../images/icons/location-pin.svg';
import { loyaltyContractAddress } from '../utils/network';
interface RedeemProps { 
  token: string; 
  close: () => void;
}

const Redeem = ({token, close}: RedeemProps) => {

  const [signature, setSignature] = useState('');
  const [dismissAnim, setDismissAnim] = useState(false);

  useEffect(() => {
    (async () => {
      const sig = await signRedeemPayload(token);
      setSignature(sig);
    })();
  }, [token]);

  const signRedeemPayload = async (token: string) => {
    let user = await magic.user.getInfo();
    let address = user.publicAddress;
    // The payload is the end user wallet address and tokenId
    const reedemPayload = {
      userAddress: address,
      tokenId: token,
    }
    // Types for signature including Domain and RedeemTransaction
    const types = {
      EIP712Domain: [
        { name: "name", type: "string" },
        { name: "version", type: "string" },
        { name: "verifyingContract", type: "address" },
        { name: "chainId", type: "uint256" },
      ],
      RedeemTransaction: [
        { name: "userAddress", type: "address" },
        { name: "tokenId", type: "uint256" },
      ]
    };
    // This order matters
    const domain = {
      name: "Loyalty", // Name of Smart Contract
      version: "1",
      verifyingContract: loyaltyContractAddress, // Replace with loyalty contract address
      chainId: 80001,
    };
    // Payload to actually be signed
    const signTypedDataV4Payload = {
      domain: domain,
      message: reedemPayload,
      types: types,
      primaryType: 'RedeemTransaction',
    };
    // Generating the signature with the end user's wallet
    const params = [address, signTypedDataV4Payload];
    const method = "eth_signTypedData_v4";
    const signature = await magic.rpcProvider.request({ method, params });
    // console.log(signature);
    // console.log(token);
    return JSON.stringify([token,signature, address]);
  }

  const anim = dismissAnim?{animation: 'modalFadeOut 0.3s forwards'}:{animation: 'modalFadeIn 0.3s forwards'};
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 mx-auto" style={anim}>
    <div className="mx-auto relative container h-[467px] w-[343px] bg-[#3b3c43] shadow-[0px_0px_32px_#52c7df] rounded-[8px] top-[72px]">
      <div className='left-[24px] top-[15px] relative'><img src={pblogo}></img></div>
      <div className="w-[227px] left-[100px] top-[15px] h-[62px] absolute">
        <div className="[font-family:'Open Sans'] w-[208px] left-0 tracking-[0] text-[14px] top-[14px] absolute h-[48px] font-normal leading-[normal] text-white">
          <span className="font-bold">
            Partner Brand Retail
            <br />
          </span>
          <span className="[font-family:'Open Sans']">
            <>$100 off</>
          </span>
        </div>
        <div className="w-[24px] h-[24px] left-[203px] tracking-[0] text-[16px] top-0 absolute" onClick={()=> { setDismissAnim(true); close();}}>
          <a style={{cursor:'pointer'}}><img src={closeIcon}></img></a>
        </div>
      </div>
      <div className="w-[295px] left-[24px] top-[100px] bg-no-repeat bg-center bg-fill absolute h-[352px] bg-[url('images/qrBackground.png')]">
        <div className="relative">
          <Tag
            className="!h-[28px] !w-[157px] !relative !left-[139px]"
            divClassName="!h-[24px] !text-[15px] !left-[6px] !w-[100px] top-[5px]"
            overlapGroupClassName="!h-[26px] !left-[44px] !w-[112px] !top-[-2px]"
            status="reedemnow"
            rectangleClassName="!h-[24px] !rounded-[0px_6px_0px_6px] !w-[112px] !top-[2px]"
          />
          <div className="flex justify-center items-center h-[250px]">
            <div className="mx-auto rounded-[17px] h-[200px] w-[200px] bg-white absolute">
              { signature === "" && <div className="relative h-[180px] w-[180px] top-[80px] left-[10px] h-full w-full"><BusySpinner ></BusySpinner></div>}
              { signature !== "" && <QRCode
                    value={signature}
                    size={180}
                    qrStyle='dots'
                    logoImage={qrLogo}
                    logoWidth={34}
                    logoHeight={34}
                    removeQrCodeBehindLogo={true}
                    ecLevel="L"
                    eyeRadius={8}
                    bgColor="rgba(0, 0, 0, 0)"
                />
              }
            </div>
          </div>
        </div>
      </div>
      <div className="top-[320px] relative flex justify-center items-center">
        <div className="mx-auto relative w-[295px] h-[28px] top-0 left-0 [font-family:'Open Sans',Helvetica] font-semibold text-white text-[20px] text-center tracking-[0] leading-[normal]">
          Scan for $100 off
        </div>
      </div>
      <p className="absolute w-[117px] top-[422px] left-[180px] font-normal text-white text-[14px] text-center tracking-[0] leading-[normal]">
        <span className="absolute left-[-4px]">
            <img src={linkOut}></img> 
          </span>
          <span className="[font-family:'Open Sans'] font-semibold">Store Locator</span>
        </p>
        <p className="absolute w-[109px] top-[422px] left-[60px] font-normal text-white text-[14px] text-center tracking-[0] leading-[normal]">
          <span className="absolute left-[-4px]">
          <img src={locationPin}></img> 
          </span>
          <span className="[font-family:'Open Sans',Helvetica] font-semibold">Shop Online</span>
        </p>
    </div>
    </div>
  );
};
export default Redeem;