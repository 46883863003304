import React, {useState, useEffect, useCallback} from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { magic } from '../magic/MagicProvider';
import { getToken, saveToken } from "../utils/common";
import BusySpinner from "../components/BusySpinner";
import {endpointUser} from '../utils/network'
import ModalDialog from "../components/ModalDialog";
import Card from "../components/Card";

const LoginLoadingPage = () => {

    const [searchParams] = useSearchParams();
    let message = 'Logging in...';

    const [loadingMessage, setLoadingMessage] = useState(message);
    const [isLoading, setIsLoading] = useState(true);

    let login = searchParams.get('login') || "redirect";

    let token  = login === "redirect" ? searchParams.get('magic_credential') || "" : getToken() || "";

    const navigate = useNavigate();

    const validateTokenAndLoadHome = useCallback(async (token:string) => {
        try {
            //Validate the token
            if (login === "redirect") {
                await magic.auth.loginWithCredential(token);
                
                //Save the token again, since it gets removed between page loads (to support incognito mode)
                saveToken(token);
            }
    
            const userInfo = await magic.user.getInfo();

            console.log(userInfo);

            const response = await fetch(endpointUser + "?email=" + userInfo.email, {
                method:'GET',
                mode:'cors',
                credentials:'omit',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': token || ""
                },
            });

            console.log("After fetch: " + response);

            if( !response.ok) {
                throw new Error(response.statusText + " " + userInfo.email);
            }

            setLoadingMessage("Loading your page...");

            const responseText = await response.text();
            console.log(responseText);

            const responseJson = JSON.parse(responseText);

            //navigate to consumer or merchant page depending on if the response object returns user or merchant.
            let stateObject = {email: userInfo.email};
            responseJson.userType === 'user' ? navigate('/consumerHome', {state: stateObject}) : navigate('/merchantHome', {state: stateObject});
    
        } catch (e:any){
        
          console.log(e);
          setLoadingMessage(e.message);
          setIsLoading(false);
        }
      }, []);
    
    useEffect(() => {
          validateTokenAndLoadHome(token);
    }, []);  

    return (
                <div className="h-screen bg-gray-200 w-full flex justify-center items-center">
                    <Card>
                        <h1 className="text-blue-500 text-2xl text-center">{loadingMessage}</h1>
                        {isLoading && <BusySpinner/>}
                    </Card>
                </div>
            )
};

export default LoginLoadingPage;