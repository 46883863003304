import { useState,  useEffect, useCallback } from "react";
import Card from "../components/Card";
import { useLocation } from "react-router-dom";
import MerchantLoggedinHeader from "../components/MerchantLoggedinHeader";
import RedeemedTokens from "../components/RedeemedTokens";

const MerchantLoggedInPage = (props:any) => {

    //extract state passed from the previous page
    const {state} = useLocation();

    //fetch punch count from alchemy rpc

    const getMerchantTransactions = useCallback(async () => {


    }, []);

    useEffect(() => {
        getMerchantTransactions();
  }, []);  

    return (
        
        <Card>
            <MerchantLoggedinHeader email={state.email}/>
            <RedeemedTokens/>
        </Card>
    );
    };


export default MerchantLoggedInPage;