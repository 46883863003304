import React, {useState}  from "react";

const LoginForm = (props:any) => {

    const [isLoginInProgress, setIsLoginInProgess] = useState(false);

    const goToRegisterHandler = () => {
        props.onSelectRegisterOrLogin(true);
    };

    const loginClickHandler = () => {
        
        setIsLoginInProgess(true);

        props.onLoginClick();

    };

    return (
        <div>
            <div>
                <div className="mt-3">
                    <button onClick={loginClickHandler}  disabled={isLoginInProgress || !props.buttonEnabled}  
                    className="font-bold block py-3.5 w-full bg-[#3B3C43] hover:bg-gray-500 disabled:cursor-not-allowed text-white text-sm rounded-md">SIGN IN</button>
                </div>
                <div className="flex justify-center">
                    <a href="#Register" onClick={goToRegisterHandler} className="text-blue-500 text-xs font-sans mt-3">Click here to register for NFT rewards</a>
                </div>
            </div>
    </div>
    );
};

export default LoginForm;