import { ethers } from 'ethers';

import contractAbi from './contractAbi';
import eventsAbi from './eventsAbi';
import { getWalletAddress, magic } from '../magic/MagicProvider';
import { Network, Alchemy, NftTokenType, NftOrdering, OwnedNftsResponse, GetTransfersForOwnerTransferType, GetTransfersForOwnerOptions } from "alchemy-sdk";
import { polygonMumbai, loyaltyContractAddress, alchemyAPIKey, gaslessTxnUrl } from './network';
import { getToken } from './common';
import { isNullOrUndefined } from 'html5-qrcode/esm/core';


const getLoyaltyContract = async ():Promise<ethers.Contract> => {
    try{    
        return getContract(contractAbi);
    } catch(e) {
        throw new Error("Error while getting loyalty contract");
    }
}

const getEventsContract = async ():Promise<ethers.Contract> => {
    try{    
        return getContract(eventsAbi);
    } catch(e) {
        throw new Error("Error while getting events contract");
    }
}

const getContract = (aabi: string) => {
        const abiInterface = new ethers.Interface(contractAbi);

        const alchemyProvider = new ethers.AlchemyProvider(polygonMumbai, alchemyAPIKey);
    
        const contract = new ethers.Contract(loyaltyContractAddress, abiInterface, alchemyProvider);
    
        return contract;
}


///This method returns the punch tokens for the logged-in user after querying the blockchain 
//uses ehters.js

export const getPunchTokens = async (): Promise<number> => {

    try {

        const walletAddress = await getWalletAddress() || "";

        if(walletAddress === "") {
            throw new Error("Wallet address is empty");
        }

        const contract = await getLoyaltyContract();

        const punchTokens = await contract.getPunchesByWallet(walletAddress);

        console.log("After getting punch tokens: " + punchTokens);

        return punchTokens;

    }catch (e) {

        console.log(e);
        throw new Error("Error while trying to get tokens for the current user.");
    }
}

export const setMerchantWallet = async (newMerchantAddress: string) => {
    const abiInterface = new ethers.Interface(contractAbi);
    const provider = new ethers.BrowserProvider(magic.rpcProvider);
    const signer = await provider.getSigner();
    const walletAddress = (await getWalletAddress()) ||"";
    const bal = await provider.getBalance(walletAddress)
    console.log("Balance: "+bal);
    const contract = new ethers.Contract(loyaltyContractAddress, abiInterface, signer);
    const addResult = await contract.addMerchant(newMerchantAddress);
    console.log("set merchant: ");
    console.dir(addResult)
}

//get token balances and token IDs for a given wallet
export const getTokensForCurrentUser = async () : Promise<string[]> => {
    
    try {

        const walletAddress = await getWalletAddress() || "";
        console.log("Current wallet address: " + walletAddress);

        if(walletAddress === "") {
            return [];
        }
        
        const contract = await getLoyaltyContract();

        const balance = await contract.balanceOf(walletAddress); 

        console.log("Token Balance: " + balance);
        
        let tokenIds:string[] = [];
        
        for(let i = 0; i < balance; i++) {

            const tokenId = await contract.tokenOfOwnerByIndex(walletAddress, i); 
            tokenIds.push(tokenId.toString());

            // console.log("Token ID: " + tokenId);
        }

        return tokenIds;

    } catch(e) {

        console.log("Error while trying to get token IDs for the current user." + e);
        throw new Error("Error while trying to get token IDs for the current user." + e)
    }

}

//Use alchemy-sdk to retrive token information for a wallet
//Currently returns the top 5 most recent tokens

export interface TokenMetaData {tokenId:string, tokenDate:string, txnHash:string};

export const getAllTokensForWallet = async ():Promise<TokenMetaData[]> => {

    try {
        const settings = {
            apiKey: alchemyAPIKey, // Replace with your Alchemy API Key.
            network: Network.MATIC_MUMBAI, // Replace with your network.
        };

        const alchemy = new Alchemy(settings);

        const walletAddress = await getWalletAddress() || "";

        if(walletAddress === "") {
            throw new Error("Wallet address is empty");
        }

        const nftResponse:OwnedNftsResponse = await alchemy.nft.getNftsForOwner(walletAddress, { 
            contractAddresses: [loyaltyContractAddress],
            orderBy: NftOrdering.TRANSFERTIME,
        })

        let allTokens:TokenMetaData[] = [];
        
        if (nftResponse.totalCount === 0) return allTokens;
        
        //console.log("NFT Data:" + JSON.stringify(nftResponse));
        const sortedTokens = nftResponse.ownedNfts.sort((a, b) => {

            const aInt = parseInt(a.tokenId);
            const bInt = parseInt(b.tokenId);

            return bInt - aInt;
        });

        const topFive = sortedTokens.slice(0,5);

        allTokens = topFive.map((item) => {
            let dateObj = item.acquiredAt?.blockTimestamp ? new Date(item.acquiredAt.blockTimestamp) : new Date(item.mint?.timestamp || "");
            let td:any;

            if (dateObj){
                td = dateObj.getFullYear() + "-" + 
                dateObj.getMonth() + "-" +
                dateObj.getDate() + " " +
                dateObj.getHours() + ":" +
                dateObj.getMinutes() + ":" +
                dateObj.getSeconds();
            } else {
                td = 'Date not available'
            }

            return {
                tokenId: item.tokenId,
                tokenDate: td,
                txnHash:item.mint?.transactionHash || ""
            }
        });

        return allTokens;

    } catch(e) {

        throw new Error("Error while getting token metadata");
    }
}

export const redeemToken = async ( token:string, signedTx:string, consumerAddress: string, resultsCallback:any) => {

    try{

        console.log("txn: " + signedTx);
        console.log("token: " + token);
  
        const walletAddress = await getWalletAddress() || "";
  
        if(walletAddress === "") {
            throw new Error("Wallet address is empty");
        }
  
        // Create gasless transaction to redeem utility token
        const redeemTransaction = {
                userAddress: consumerAddress,
                tokenId: parseInt(token),
            };
  
        const contract = await getLoyaltyContract();

        // Create the redeem transaction
        let tx = await contract.redeem.populateTransaction(
                redeemTransaction,
                signedTx
            )

            console.log("redeeming wallet: " + walletAddress);

        // Merchant wallet (logged in)
        const res = await magic.wallet.sendGaslessTransaction(
                walletAddress, // Merchant wallet address passed in
                tx
            );

        console.log("sendGaslessTransaction response: " + res);

        await resultsCallback(res.request_id);
  
    } catch(e) {
        throw new Error("Error while redeeming the token. " + e);
    }
  }

  export const getTransactionResult = async (requestId: string): Promise<[string, string]> => {

    const response = await fetch(gaslessTxnUrl + "?request_id=" + requestId, {
        headers: {
        'Authorization': 'Bearer ' + getToken()
        },
    });

    console.log(response);
    const responseText = await response.text();
    console.log(responseText);
    const responseObj = JSON.parse(responseText);

    //if state is pending nothing to do but wait
    if (responseObj.state === "PENDING") return [responseObj.state, ""];

    //if state has failed, nothing to do again but end the requesting cycle
    if (responseObj.state === "FAILED") return ["RED_FAILED", ""];
   
    //if state is completed, check the event data to see how it completed
    const iFace = new ethers.Interface(eventsAbi);


    let returnState = "", txHash = "";

    responseObj.tx_receipt.logs.forEach((log:any) => {
        const parsedLog = iFace.parseLog(log);

        if(parsedLog?.name === "AdminActionFailed" || parsedLog?.name === "FailedSignatureVerification" || 
            parsedLog?.name === "OwnerDoesNotOwnToken") {
                 returnState = "RED_FAILED";
                 txHash = responseObj.tx_hash;
                console.log("Redemption result: " + responseObj.state);
            }
        else if(parsedLog?.name === "UtilityTokenRedeemed") {
            returnState = "RED_SUCCEEDED";
            txHash = responseObj.tx_hash;
            console.log("Redemption result: " + responseObj.state);
        }
    });
    
    return [returnState, txHash];
  }

  export interface TransferredTokens {tokenId:string, lastUpdate:string, txnHash:string};

  //Gets all the tokens redeemed by a merchant wallet
  //Currently returns the top 5 most recent tokens
  export const getRedeemedTokens = async () : Promise<TransferredTokens[]> => {

    let rewards:TransferredTokens[] = [];

    try {

        const settings = {
            apiKey: alchemyAPIKey, // Replace with your Alchemy API Key.
            network: Network.MATIC_MUMBAI, // Replace with your network.
        };

        const alchemy = new Alchemy(settings);        

        const walletAddress = await getWalletAddress() || "";
  
        if(walletAddress === "") {
            throw new Error("Wallet address is empty");
        }

        const options:GetTransfersForOwnerOptions = {
   
            contractAddresses: [loyaltyContractAddress],
            /**
             * Filter mints by ERC721 vs ERC1155 contracts. If omitted, defaults to all
             * NFTs.
             */
            tokenType: NftTokenType.ERC721,
          };

        // Calling the getTransfersForOwner method
        const transfers = await alchemy.nft.getTransfersForOwner(
            walletAddress,
            GetTransfersForOwnerTransferType.FROM,
            options
        );

        console.log("Transfers for this owner: " + JSON.stringify(transfers));

        if(transfers.nfts.length === 0) return rewards;

        //sort the rewards array and get the top 5 elements
        const sortedTransfers = transfers.nfts.sort((a, b) => {

            const aInt = parseInt(a.tokenId);
            const bInt = parseInt(b.tokenId);

            return bInt - aInt;
        });

        const topFive = sortedTransfers.slice(0, 5);

        console.log("Sorted top five NFTs: " + JSON.stringify(topFive));

        console.log("Top five: " + topFive.length);
        
        rewards = topFive.map((item) => {

            let timeStamp = '';

            if(!isNullOrUndefined(item.timeLastUpdated) && item.timeLastUpdated !== '') {
                const timeObj = new Date(item.timeLastUpdated);
                timeStamp = timeObj.getFullYear() + "-" + 
                            timeObj.getMonth() + "-" +
                            timeObj.getDate() + " " +
                            timeObj.getHours() + ":" +
                            timeObj.getMinutes() + ":" +
                            timeObj.getSeconds();
            }

            const  retObject: TransferredTokens =  {
                tokenId: item.tokenId,
                lastUpdate: timeStamp,
                txnHash: item.transactionHash
            }

            return retObject;
        });

        console.log(rewards);
            
        
    }catch (e) {

        let errorMsg = "Error while getting transfered tokens: " + e;

        console.log(errorMsg);
        throw new Error(errorMsg);
    }

    return rewards;
  }