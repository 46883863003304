import react from 'react'
import RedeemPending from '../images/RedeemPending.png'
import RedeemSucceeded from '../images/RedeemSucceeded.png'
import RedeemFailed from '../images/RedeemFailed.png'
import leftArrow from '../images/left-arrow.png';
import { Link } from 'react-router-dom';

const RedeemStatus = (props:any) => {

    console.log("Final redeem status: " + props.redeemStatus);

    return (
        <>
            {
                (props.redeemStatus === "PENDING") &&
                <div>
                    <div className='flex'>
                        <img src={RedeemPending} className='w-full'></img>
                    </div>
                    <div>
                        <p className='px-5 text-[#727483] text-[14px] font-medium leading-[18px]'>Redeem Requested: 
                                    <span className='text-[#3B3C43] text-[14px] font-medium leading-[18px] font-sans'>{props.startTime}</span></p>
                        <p className='px-5  text-[#727483] text-[14px] font-medium'>Blockchain TID: Pending - This can take about 15 seconds to complete.</p>
                    </div>
                </div>
            }
            {
                (props.redeemStatus === "RED_SUCCEEDED") &&
                <div>
                    <div className='flex'>
                        <img src={RedeemSucceeded} className='w-full'></img>
                    </div>
                    <div>
                        <p className='px-5 text-[#727483] text-[14px] font-medium leading-[18px]'>Redeem: 
                                <span className='text-[#3B3C43] text-[14px] font-medium leading-[18px] font-sans'>{props.confirmedTime}</span></p>
                        <p className='px-5 text-sm'>Blockchain TID: <Link to={props.polygonScanLink} className='px-5 text-sm underline text-blue-500'>{props.txnHash}</Link></p>
                    </div>
                </div>
            }
            {
                (props.redeemStatus === "RED_FAILED") &&
                <div>
                    <div className='flex'>
                        <img src={RedeemFailed} className='w-full'></img>                
                    </div>
                    <div>
                        <p className='px-5 text-[#3B3C43] text-[14px] font-medium leading-[18px] font-sans'>Errors like this can happen when A a token has already been redeemed, a network failure occured or cosmic rays flipped our bits.</p>
                        <p className='px-5 text-[#3B3C43] text-[14px] font-medium leading-[18px] font-sans my-2'>Email us for assistance:  <span className='text-bold text-[#52C7DF]'>info@test.com</span> </p>
                    </div>
                </div>
            }
           <div className="mt-3 flex flex-row items-center ">
                <img src={leftArrow} className= 'w-8 h-8 left-[80px] relative'/>
                <button onClick={props.returnToMainScreen} disabled={props.redeemStatus === "PENDING"}
                                className="font-bold block py-3.5 w-full bg-[#3B3C43] hover:bg-gray-500 text-white text-sm rounded-md">RETURN TO TABLE</button>
            </div>

        </>
    );
};

export default RedeemStatus;