import punchEmpty from '../images/punch-empty.svg'

const SpendItemEmpty = (props:any) => {

    const itemClosed = ">";

    return (
        <div className="flex">
            <div className="relative w-10 h-10">
                <img id={props.spendItem} key={props.spendItem} className="w-full h-full" src={punchEmpty}/>
                <div className="absolute inset-0 flex items-center justify-center text-sm  text-[#2b2d2d99]">{props.spendItem + 1}</div>
            </div>
        </div>

    )
}

export default SpendItemEmpty;