import PropTypes from "prop-types";
import React from "react";

interface Props {
  status: "redem-date" | "reedemed" | "reedemnow";
  className: any;
  overlapGroupClassName: any;
  rectangleClassName: any;
  divClassName: any;
}

// ugly generated component via anima

export const Tag = ({
  status,
  className,
  overlapGroupClassName,
  rectangleClassName,
  divClassName,
}: Props): JSX.Element => {
  return (
    <div
      className={`w-[105px] h-[16px] ${status === "redem-date" ? "bg-[#d9d9d9]" : ""} ${
        status === "redem-date" ? "relative" : ""
      } ${className}`}
    >
      <div
        className={`-top-px ${status === "redem-date" ? "[font-family:'Open_Sans-Regular',Helvetica]" : ""} ${
          status === "reedemed" ? "w-[59px]" : status === "redem-date" ? "w-[98px]" : "w-[75px]"
        } ${status === "reedemed" ? "left-[46px]" : status === "redem-date" ? "left-[3px]" : "left-[30px]"} ${
          status === "redem-date" ? "tracking-[0]" : ""
        } ${status === "redem-date" ? "text-[10px]" : ""} ${status === "redem-date" ? "text-[#3b3c43]" : ""} ${
          status === "redem-date" ? "h-[16px]" : "h-[17px]"
        } ${status === "redem-date" ? "font-normal" : ""} ${status === "redem-date" ? "text-right" : ""} ${
          status === "redem-date" ? "leading-[normal]" : ""
        } ${status === "redem-date" ? "absolute" : "relative"} ${overlapGroupClassName}`}
      >
        {["reedemed", "reedemnow"].includes(status) && (
          <>
            <div
              className={`left-0 top-px h-[16px] rounded-[0px_4px_0px_4px] absolute ${
                status === "reedemed" ? "w-[59px]" : "w-[75px]"
              } ${status === "reedemed" ? "bg-[#d9d9d9]" : "bg-[#3e8529]"} ${rectangleClassName}`}
            />
            <div
              className={`left-[4px] tracking-[0] text-[10px] top-0 h-[16px] text-right leading-[normal] absolute ${
                status === "reedemed"
                  ? "[font-family:'Open_Sans-Regular',Helvetica]"
                  : "[font-family:'Open_Sans-Bold',Helvetica]"
              } ${status === "reedemed" ? "w-[51px]" : "w-[67px]"} ${
                status === "reedemed" ? "text-[#3b3c43]" : "text-white"
              } ${status === "reedemed" ? "font-normal" : "font-bold"} ${divClassName}`}
            >
              {status === "reedemnow" && <>Redeem Now</>}

              {status === "reedemed" && <>Redeemed</>}
            </div>
          </>
        )}

        {status === "redem-date" && <>Redeemed 09/25/23</>}
      </div>
    </div>
  );
};

Tag.propTypes = {
  status: PropTypes.oneOf(["redem-date", "reedemed", "reedemnow"]),
};
