import React, {useState, useEffect} from 'react';
import AuthPage from './pages/AuthPage'

import ConsumerLoggedInPage from './pages/ConsumerLoggedInPage';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import MerchantLoggedInPage from './pages/MerchantLoggedInPage';
import RegisterLoadingPage from './pages/RegisterLoadingPage';
import LoginLoadingPage from './pages/LoginLoadingPage';

const router = createBrowserRouter([
  {path: '/', element: <AuthPage />},
  {path: '/ConsumerHome', element: <ConsumerLoggedInPage/>},
  {path: '/MerchantHome', element: <MerchantLoggedInPage/>},
  {path: '/LoginLoadingPage', element: <LoginLoadingPage/>},
  {path: '/RegisterLoadingPage', element: <RegisterLoadingPage/>}
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
