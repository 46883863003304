import React, {useCallback, useEffect, useState} from 'react'
import {redeemToken, getAllTokensForWallet, TokenMetaData, getTransactionResult} from '../utils/dataAccessLayer';
import { Link } from 'react-router-dom';
import {Html5QrcodeScanner, Html5Qrcode} from 'html5-qrcode'
import RedeemStatus from './RedeemStatus';
import { mumbaiPolygonScan } from '../utils/network';
import {getClippedTxnHash} from '../utils/common'
import nft from '../images/nft.png'
import reward from '../images/reward.png'

import { isNullOrUndefined } from 'html5-qrcode/esm/core';

const RedeemedTokens = (props:any) => {

    let redeemedTokens:TokenMetaData[] = [];
    let tokenRows:any = [];

    const [tokens, setTokens] = useState(redeemedTokens);
    const [scanResult, setScanResult] = useState(null);
    const [redeemState, setRedeemState] = useState('');
    const [txHash, setTxHash] = useState('');
    const [startTime, setStartTime] = useState('');
    const [confirmedTime, setConfirmedTime] = useState('');

    let resultVisual:any;


    if(tokens.length > 0) {

        tokenRows = tokens.map((item) => {
            const txnHashLink = mumbaiPolygonScan + item.txnHash; 
            console.log("Actual date value: " + item.tokenDate);
            const tokenDate = item.tokenDate === '' || isNullOrUndefined(item.tokenDate) 
                || item.tokenDate.includes("NaN") ? "Waiting to cache..." : item.tokenDate;
            return (
                <tr className='flex bg-white' id={item.tokenId} key={item.tokenId}>
                    <td className='flex-1 w-1/2 p-2 rounded border-dotted border-b-2 border-r-2 border-[##3B3C43] text-left text-[16px] text-[#3B3C43]font-["Open_Sans"] font-medium leading-[18px]'>{tokenDate}</td>
                    <td className='flex-1 w-1/2 p-2 text-center border-dotted border-r-2 border-b-2 border-[##3B3C43]'>
                        <div className='flex'><img className="w-[40px] h-[25px]" src={nft}/>
                            <Link to={txnHashLink} className='inline pt-1 text-[#14px] font-sans font-semibold underline text-[#1B7D93]'>{getClippedTxnHash(item.txnHash)}</Link>
                        </div>
                    </td>
                </tr>
            );

        });

    }


    const getTokens = useCallback(async () => {

        redeemedTokens = await getAllTokensForWallet();

        setTokens(() => {
            return [...redeemedTokens];
        })

    }, []);

    useEffect(() => {

        getTokens();

    }, [])

    const redeemClickHandler = () => {

        setRedeemState("SCANNER_OPEN");

        setTimeout(() => {
            getScanner();
        }, 300);
        
    }


   const getScanner = () => {

        const newScanner = new Html5QrcodeScanner('reader', {
            qrbox: {
                width: 250,
                height: 250
            },
            fps: 2
        }, 
        false);

        const onSuccess = async (result:any) => {
            newScanner.clear();
            setScanResult(result);
            setRedeemState("PENDING");

            try {

                console.log(result);

                const scanResult = JSON.parse(result);

                console.log(scanResult);

                //Get the current time stamp
                const now = new Date(Date.now())
                setStartTime(now.getFullYear() + "-" + 
                            now.getMonth() + "-" +
                            now.getDate() + " " +
                            now.getHours() + ":" +
                            now.getMinutes() + ":" +
                            now.getSeconds());
            

                //call the redeem function in the smart contract
                await redeemToken(scanResult[0], scanResult[1], scanResult[2], analyzeRedeemEvents);

            } catch(e) {
                console.log("Error while handling onSuccess of the scanner: " + e);
                setRedeemState("RED_FAILED");
            }
        }
    
        const onError = (error:any) => {
            
            console.log(error);
    
        }
    
        
        newScanner.render(onSuccess, onError);        
    }

    const returnToMainScreenHandler = () => {

        setRedeemState('');
    }

    const analyzeRedeemEvents = async (requestId: string) => {

        const max_attempts = 20;
        let attempts = 0;

        const getResults = async () => {

            attempts++;

            if(attempts >= max_attempts) {
                setRedeemState("RED_FAILED");
                return;
            }

            const [st, txHash] = await getTransactionResult(requestId);
            if(st === "PENDING") 
            {
                setTimeout(getResults, 3000, requestId);
                return;
            }

            console.log("Setting final redeem status: " + st);
            setRedeemState(st);
            setTxHash(txHash);
            
            const now = new Date(Date.now());
            setConfirmedTime(now.getFullYear() + "-" + 
            now.getMonth() + "-" +
            now.getDate() + " " +
            now.getHours() + ":" +
            now.getMinutes() + ":" +
            now.getSeconds())

        }

        await getResults();

    }


    return (
       <>
                {
                    (redeemState === "PENDING" || redeemState === "RED_FAILED" || redeemState === "RED_SUCCEEDED") && 
                    <RedeemStatus redeemStatus={redeemState} txnHash={getClippedTxnHash(txHash)} polygonScanLink={mumbaiPolygonScan+txHash} 
                                startTime={startTime} confirmedTime={confirmedTime} returnToMainScreen={returnToMainScreenHandler}/>
                }

                { 
                    (redeemState === "SCANNER_OPEN" ) && 
                    <>                    
                        <div id="reader">
                        </div>
                        <p className="m-4"><a style={{cursor:'pointer'}} className="block text-[#52C7DF] font-normal text-sm mx-5 text-center" 
                                    onClick={returnToMainScreenHandler}>Close scanner</a></p>
                    </>
                }

                {
                    (redeemState === '' || redeemState === 'SCANNER_OPEN') &&

                    <div className='py-3'>
                        <div className="mt-3 flex flex-row items-center ">
                            <img src={reward} className= 'w-5 h-5 left-[80px] relative'/>
                            <button onClick={redeemClickHandler}  disabled={redeemState === "SCANNER_OPEN"}  
                            className="font-bold block py-2 w-full bg-[#3B3C43] hover:bg-gray-500 disabled:opacity-50 
                                        disabled:cursor-not-allowed text-white text-sm rounded-md">REDEEM A NEW TOKEN</button>
                        </div>
                        <table className='w-full my-5'>
                            <thead>
                                <tr className='flex' id="headingRow" key="headingRow">
                                    <th className='flex-1 w-1/2 border-b-2 border-[#52C7DF]'>Redeemed</th>
                                    <th className='flex-1 w-1/2 border-b-2 border-[#52C7DF]'>Blockchain TID</th>
                                </tr>

                            </thead>
                            <tbody>
                                {tokenRows}
                            </tbody>
                        </table>
                    </div>   
                }
                
        
        </>
    )

}

export default RedeemedTokens;