import React from "react";

const Card = (props:any) => {    
    return (
        <div>
            <div className="w-full absolute h-[160px] bg-white z-[-1] shadow-[0_4px_16px_0_rgba(0,0,0,0.3)]"></div>
            <div className="w-full absolute top-[160px] h-full bg-[#f4f4f6] z-[-2] "></div>
            <div className={"w-96 mx-auto p-5 " + props.className} >
                {props.children}
            </div>
        </div>
    )
};

export default Card;