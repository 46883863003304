import React from "react";
import Header from '../components/Header';
import PunchCard from '../components/PunchCard';
import AuthForm from '../components/AuthForm';
import Card from "../components/Card"

const AuthPage = (props:any) => {
    return (
            <Card>
                <Header></Header>
                <PunchCard hostPage="authForm"></PunchCard>
                <AuthForm></AuthForm>
            </Card> 
    )
};

export default AuthPage;