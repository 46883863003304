import { Magic } from './types';
import { Dispatch, SetStateAction } from 'react';

export type LoginMethod = 'EMAIL' | 'SMS' | 'SOCIAL' | 'FORM';

export const logout = async (setToken: Dispatch<SetStateAction<string>>, magic: Magic | null) => {
  if (await magic?.user.isLoggedIn()) {
    await magic?.user.logout();
  }
  localStorage.setItem('token', '');
  localStorage.setItem('user', '');
  setToken('');
};

export const saveToken = (token: string) => {
  localStorage.setItem('token', token);
};

export const getToken = () :string => {
  return localStorage.getItem('token') || "";
}

export  const getClippedTxnHash = (txnHash:string): string => {

  console.log("Full txn hash: " + txnHash);

  const clipped = (txnHash.length == 0) ? "" : txnHash.substring(0,6) + "..." + txnHash.substring(txnHash.length - 4);

  console.log("Clipped txn hash: " + clipped);

 return clipped;
}