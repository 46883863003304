import topPartnerLogo from "../images/logo.svg"

const LoggedinHeader = (props: any) => {
    return (
        <>
            <div className="flex ">
                <div>
                    <img src={topPartnerLogo} className="w-full"/>
                </div>
                <span className="text-right justify-end grow m-6 mt-4 mr-0 text-[#727483]">{props.email}</span>
            </div>
        </>
    );
};

export default LoggedinHeader;