import React, {useState, useEffect} from "react";
import logo from '../images/PB-Logo.svg';
import punchOpen from "../images/punch-open.svg"
import punchDone from "../images/punch-done.svg"
import punchComplete from "../images/punch-complete.png"

import dotenv from "dotenv";
import { Link } from "react-router-dom";
import Redeem from "./Redeem";
import { getToken } from "../utils/common";
import { getTokensForCurrentUser } from "../utils/dataAccessLayer";
import { number } from "prop-types";

const PunchCard = (props: any) => {
    const [isRedeemShowing, setIsRedeemShowing] = useState(false);
    const showRedeem = () => {
        setIsRedeemShowing(true);
    }
    const hideRedeem = () => {
        setTimeout(() => {
            setIsRedeemShowing(false);
        }, 300);
    }

    const [tokens, setTokens] = useState([] as string[]);
    useEffect(() => {
        (async () => {
            let tokens = props.hostPage === "consumerPage" ? await getTokensForCurrentUser() : [];
            setTokens(tokens);
        })();
    }, [])

    const punchImages = [];
    let i = 0;

    let p:number = props.punches || 0;
    const [punches, setPunches] = useState(p);
    console.log("Total punches received: " + p + " " + p);


    //fill with open stars
    let displayCard = <></>;

    for(; i < 5; i++) {
        const id = "punch"+ i.toString();
        punchImages[i] = (
            <div key={id} className="flex items-center justify-center h-full m-2">
            <div className="relative w-12 h-12">
                <img id={id} key={id} className="w-full h-full" src={punchOpen}/>
                <div className="absolute inset-0 flex items-center justify-center text-sm  text-[#dde5e699]">{i + 1}</div>
            </div>
        </div>
        );
    }

    //get the mod 5 of the punch tokens
    let newPunches = (Number(p) % Number(5));

    //if there are no utility tokens, fill the incomplete card
    if(tokens.length == 0) {

        displayCard =   <div className="bg-paper-texture bg-center rounded-[8px] py-5">
                            <img className="mx-auto" src={logo}></img>
                            <div className="block text-white font-bold text-l mx-5 mt-5 text-center">Get $100 off after your 5th purchase</div>
                            <div className="flex justify-center">
                                {punchImages}
                            </div>
                            <div className="block text-white font-normal text-sm mx-5 text-center"><span className="align-top">*</span>Earn a star with a purchase of $25 or more.</div>
                        </div>
    } else { //fill with complete card

        newPunches = 5;
        displayCard = <div className="bg-[url('/public/paper-texture-black.png')] bg-center rounded-[8px] py-5">
                        <img className="mx-auto" src={logo}></img>
                        <div className="block text-white font-bold text-sm mx-5 mt-5 text-center">You've earned $100 off your next purchase</div>
                        <div className="flex justify-center">
                            {punchImages}
                        </div>
                        <a style={{cursor:'pointer'}} className="block text-[#52C7DF] font-normal text-sm mx-5 text-center" onClick={showRedeem}>Redeem Now</a>
                    </div>
    }

    //Fill the right number of punches
    for( i = 0; i < newPunches; i++) {
        const id = "punch"+ i.toString();

            punchImages[i] = (
                <div key={id} className="flex items-center justify-center h-full m-2">
                    <div className="relative w-12 h-12">
                        <img id={id}  className="w-full h-full" src={punchComplete}/>
                    </div>
                </div>
            );
    }


    return (
        <>
            {displayCard}
            { (isRedeemShowing) && <div> <Redeem token = {tokens.length > 0 ? tokens[0] : ""} close = {hideRedeem}/> </div>}
        </>
    );
};

export default PunchCard;
